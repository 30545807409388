import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-white py-8 w-full">
      <div className="mx-20 px-4 flex flex-col sm:flex-row justify-between items-center">
        <span className="text-text_dark text-sm mb-4 sm:mb-0">© 2025 Close Scout</span>
        <div className="text-sm flex flex-col sm:flex-row"> 
          <a href="/terms-and-conditions" className="text-gray-400 hover:underline mb-2 sm:mb-0 sm:mr-4">
            Terms and Conditions
          </a>
          <a href="/privacy-policy" className="text-gray-400 hover:underline">
            Privacy Policy
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;